<template>
  <div class="body">
    <div class="plate_title">
      <div class="plate_title_name">期货商品</div>
    </div>
    <div class="flex" style="height: calc(100% - 54px)">
      <div class="left_cell">
        <el-button
          :loading="loading1"
          class="mt10 mb10"
          @click="doCut1"
          type="primary"
          >生成国内期货主力合约全景图</el-button
        >
        <div class="poster_body" id="capture1">
          <div class="pr">
            <img
              class="qihuoshangpin_head"
              src="@/assets/img/qihuoshangpin_head.png"
              alt=""
            />
            <div class="time">{{ date_time }}更新</div>
          </div>
          <div class="pl20 pr20 bb"><qihuoshangpin></qihuoshangpin></div>
          <img
            class="qihuoshangpin_foot"
            src="@/assets/img/qihuoshangpin_foot.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Service from "@/api/qihuoshangpin";
import html2canvas from "html2canvas";
import qihuoshangpin from "@/components/qihuoshangpin.vue";
export default {
  components: {
    html2canvas,
    qihuoshangpin,
  },
  data() {
    return {
      date_time: "",
      loading1: false,
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
    this.showRealTime();
  },
  methods: {
    onmessage() {},
    doCut1() {
      this.loading1 = true;
      html2canvas(document.querySelector("#capture1")).then((canvas) => {
        var imgUrl = canvas.toDataURL("image/png");
        console.log("imgUrl", imgUrl);
        setTimeout(() => {
          this.loading1 = false;
        }, 1500);
        this.submit1(imgUrl);
      });
    },
    async submit1(imgUrl) {
      const res = await Service.uploadBase64({
        pic: imgUrl,
      });
      this.addViewpointPoster(47, res.data.url);
    },
    async addViewpointPoster(type, images) {
      const res = await Service.addViewpointPoster({
        type,
        images,
      });
      this.$message.success(res.msg);
    },
    showRealTime() {
      var d = new Date();
      var year = d.getFullYear();
      var month =
        d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
      var date = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
      var days = new Array("日", "一", "二", "三", "四", "五", "六");
      var day = d.getDay();
      var hour = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
      var min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
      var sec = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();
      var cori_date = year + "-" + month + "-" + date;
      var cori_time = hour + ":" + min + ":" + sec;
      this.date_time = month + "月" + date + "日" + " " + hour + ":" + min;
      // this.currentTime = cori_time;
      // this.currentWeek = "星期" + days[day];
    },
  },
};
</script>

<style scoped>
.body {
  height: 100vh;
  overflow-y: hidden;
}

.plate_title {
  background-color: #f8fcff;
  font-size: 16px;
  font-weight: 500;
  box-sizing: border-box;
  padding: 18px 0;
  border-top: 1px solid #ebeced;
  border-bottom: 1px solid #ebeced;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.plate_title_name {
  position: relative;
  line-height: 16px;
  padding-left: 30px;
}

.plate_title_name::before {
  content: "";
  position: absolute;
  left: 13px;
  width: 4px;
  height: 16px;
  background-color: #5389f5;
  border-radius: 5px;
}

.left_cell {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
  padding: 0 150px 0 110px;
}

.poster_body {
  width: 600px;
  background-color: #272d43;
}

.qihuoshangpin_head,
.qihuoshangpin_foot {
  width: 100%;
  display: block;
}

.poster_body .time {
  width: 100%;
  bottom: 15px;
  text-align: center;
  position: absolute;
  font-weight: normal;
  font-size: 22px;
  color: #faccb1;
  line-height: 22px;
}
</style>
